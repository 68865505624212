﻿import m from "mithril";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import i18n from "../../../shared/i18n/i18n";
import Toasts from "../../../shared/toasts";
import ModalManager from "../../../shared/modal";
import Modal_Confirm_Delete from "../../../shared/components/modal_confirm_delete";
import Auth from "../../../shared/auth";
import Modal_Unit_Event_Session_Settings from "./component_unit_event_session_settings";
import DateUtilities from "../../../shared/utilities/date_utilities";
import Component_Table_Footer from "../../../shared/components/component_table_footer";
import Modal_Unit_Event_Session_New from "./modal_unit_event_session_new";
import Modal_Unit_Event_Session_New_Bulk from "./modal_unit_event_session_new_bulk";

let selected = [];
let loading, unit, filter, search, orderBy, deleting = [];
let total = 0, limit = 25, offset = 0;

function submitPagination(lim, off) {
    limit = lim;
    offset = off;
    loadSessions();
}

function loadSessions() {
    loading = true;
    unit.entities = null;
    selected = [];

    let params = [];
    if (filter)
        params.push("filter=" + filter);
    if (search)
        params.push("search=" + search);
    if (orderBy)
        params.push("orderBy=" + orderBy);

    params.push("limit=" + limit);
    params.push("offset=" + offset * limit);
    params.push("orderBy=" + orderBy);

    Requester.get(Gateway.admin + "units/event/" + unit.properties.id + "/sessions" + (params.length > 0 ? "?" + params.join("&") : ""))
        .then(function (result) {
            total = result.properties.totalCount;
            unit.entities = result.entities;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function newSession() {
    ModalManager.open(i18n.t("create_session"), [], Modal_Unit_Event_Session_New, {unit: unit.properties, callback: loadSessions});
}

function newSessionBulk() {
    ModalManager.open(i18n.t("create_bulk_sessions"), [], Modal_Unit_Event_Session_New_Bulk, {unit: unit.properties, callback: loadSessions});
}

function editSession(session) {
    ModalManager.open(i18n.t("edit_session"), [], Modal_Unit_Event_Session_Settings, {
        unitId: unit.properties.id,
        sessionId: session.properties.id,
        session: session,
        callback: loadSessions
    });
}

function toggleAllSelected() {
    if (selected.length > 0)
        selected = [];
    else {
        if (unit.entities) {
            for (let i = 0; i < unit.entities.length; i++) {
                selected.push(unit.entities[i].properties.id);
            }
        }
    }
}

function toggleSelected(id) {
    let index = selected.indexOf(id);
    if (index === -1)
        selected.push(id);
    else
        selected.splice(index, 1);
}

function confirmDeleteSessions() {
    ModalManager.open(i18n.t("delete_sessions"), [], Modal_Confirm_Delete, {callback: deleteSessions});
}

function deleteSessions() {
    deleting = true;
    Requester.delete(Gateway.admin + "units/event/sessions", selected)
        .then(function () {
            Toasts.add("success", i18n.t("saved_changes"), "");
            loadSessions();
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            deleting = false;
        });
}

function orderByName() {
    if (orderBy === "name") {
        orderBy = "name-desc"
    } else if (orderBy === "name-desc") {
        orderBy = null;
    } else {
        orderBy = "name"
    }

    loadSessions();
}

function orderByLocation() {
    if (orderBy === "location") {
        orderBy = "location-desc"
    } else if (orderBy === "location-desc") {
        orderBy = null;
    } else {
        orderBy = "location"
    }

    loadSessions();
}

function orderByStart() {
    if (orderBy === "start") {
        orderBy = "start-desc"
    } else if (orderBy === "start-desc") {
        orderBy = null;
    } else {
        orderBy = "start"
    }

    loadSessions();
}

function orderByEnd() {
    if (orderBy === "end") {
        orderBy = "end-desc"
    } else if (orderBy === "end-desc") {
        orderBy = null;
    } else {
        orderBy = "end"
    }

    loadSessions();
}

const Component_Unit_Event_Sessions = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
        filter = "today";
        loadSessions();
    },
    view: function (vnode) {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        Auth.validateRights(["unit_event_edit"], [
                            m(".toolbar-section", [
                                m("button.with-icon btn-cta", {onclick: newSession}, [m("i.icon-plus"), i18n.t("create_session")]),
                                m("button.with-icon", {onclick: newSessionBulk}, [m("i.icon-plus"), i18n.t("create_bulk_sessions")]),
                                m("button.with-icon", {
                                    onclick: confirmDeleteSessions,
                                    class: selected.length === 0 ? "disabled" : ""
                                }, [m("i.icon-delete"), i18n.t("delete_sessions")])
                            ])
                        ])
                    ]),
                    m(".toolbar-section", [
                        m(".dropdown",
                            m("button.with-icon pl-2", [
                                filter === "past" ? i18n.t("past_sessions") : filter === "today" ? i18n.t("today_sessions") : filter === "future" ? i18n.t("future_sessions") : i18n.t("all_sessions"),
                                m("i.icon-back rotate-270 ml-2")
                            ]),
                            m(".dropdown-list", [
                                m("div", {
                                    class: !filter ? "active" : "", onclick: function () {
                                        filter = null;
                                        loadSessions();
                                    }
                                }, i18n.t("all_sessions")),
                                m("div", {
                                    class: filter === "past" ? "active" : "", onclick: function () {
                                        filter = "past";
                                        loadSessions();
                                    }
                                }, i18n.t("past_sessions")),
                                m("div", {
                                    class: filter === "today" ? "active" : "", onclick: function () {
                                        filter = "today";
                                        loadSessions();
                                    }
                                }, i18n.t("today_sessions")),
                                m("div", {
                                    class: filter === "future" ? "active" : "", onclick: function () {
                                        filter = "future";
                                        loadSessions();
                                    }
                                }, i18n.t("future_sessions")),
                            ])
                        ),
                        m(".has-icon-right", [
                            m("input[type=search]", {
                                oninput: function () {
                                    search = this.value;
                                }, onkeyup: function (e) {
                                    if (e.keyCode === 13) {
                                        loadSessions();
                                    }
                                }, value: search, placeholder: i18n.t("search")
                            }),
                            m("i.icon-x c-hand", {
                                onclick: function () {
                                    search = null;
                                    loadSessions();
                                }
                            }),
                            m("i.icon-search c-hand", {
                                onclick: function () {
                                    loadSessions();
                                }
                            })
                        ])
                    ])
                ]),
                !loading ? [
                    unit && unit.entities ? [
                        m(".table-wrapper flex-auto", [
                            unit.entities.length > 0 ? [
                                m("table.full-width", [
                                    m("thead",
                                        m("tr", [
                                            m("th", m(".toolbar-checkbox", {onclick: toggleAllSelected}, m("i", {class: selected.length > 0 ? "icon-checkbox-checked" : "icon-checkbox-empty"}))),
                                            m("th.c-hand", {onclick: orderByName}, [i18n.t("name"), m("i.align-middle", {class: orderBy === "name" ? "icon-forward rotate-90" : orderBy === "name-desc" ? "icon-forward rotate-270" : ""})]),
                                            m("th.c-hand", {onclick: orderByLocation}, [i18n.t("location"), m("i.align-middle", {class: orderBy === "location" ? "icon-forward rotate-90" : orderBy === "location-desc" ? "icon-forward rotate-270" : ""})]),
                                            m("th.c-hand", {onclick: orderByStart}, [i18n.t("start"), m("i.align-middle", {class: orderBy === "start" ? "icon-forward rotate-90" : orderBy === "start-desc" ? "icon-forward rotate-270" : ""})]),
                                            m("th.c-hand", {onclick: orderByEnd}, [i18n.t("end"), m("i.align-middle", {class: orderBy === "end" ? "icon-forward rotate-90" : orderBy === "end-desc" ? "icon-forward rotate-270" : ""})]),
                                            m("th", i18n.t("actions"))
                                        ])
                                    ),
                                    m("tbody", unit.entities.map(function (session) {
                                        return m("tr", [
                                            m("td.list-checkbox c-hand pl-1 pr-1", {
                                                onclick: function () {
                                                    toggleSelected(session.properties.id);
                                                }, style: "width: 18px;"
                                            }, m("i", {class: selected.indexOf(session.properties.id) !== -1 ? "icon-checkbox-checked" : "icon-checkbox-empty"})),
                                            m("td.c-hand text-bolder", {
                                                onclick: function () {
                                                    editSession(session);
                                                }
                                            }, session.properties.name),
                                            m("td.c-hand", {
                                                onclick: function () {
                                                    editSession(session);
                                                }
                                            }, session.properties.location),
                                            m("td.c-hand", {
                                                onclick: function () {
                                                    editSession(session);
                                                }
                                            }, DateUtilities.getFormattedDate(session.properties.start ? session.properties.start : session.properties.startDateTime)),
                                            m("td.c-hand", {
                                                onclick: function () {
                                                    editSession(session);
                                                }
                                            }, DateUtilities.getFormattedDate(session.properties.end ? session.properties.end : session.properties.endDateTime)),
                                            m("td", [
                                                m("button.btn-text", {
                                                    onclick: function () {
                                                        vnode.attrs.sessionCallback(session);
                                                    }
                                                }, i18n.t("manage_attendees"))
                                            ])
                                        ])
                                    }))
                                ])
                            ] : m(".text-gray padding", i18n.t("no_sessions"))
                        ]),
                        m(Component_Table_Footer, {
                            callback: submitPagination,
                            total: total,
                            limit: limit,
                            offset: offset,
                            loading: loading,
                            item: "sessions"
                        })
                    ] : m(".text-gray padding", i18n.t("no_sessions"))
                ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
            ])
        ];
    }
};
export default Component_Unit_Event_Sessions;
